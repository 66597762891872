import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useAppState } from '../../../state'
import actions from '../../../state/configuration/actions'

import { CardButtonBase } from '../common/card/CardButtonBase'
import { SkeletonCard } from '../common/skeleton-card'

const OS_TYPES = [
  {
    id: 'linux',
    name: 'Linux',
  },
  {
    id: 'windows',
    name: 'Windows',
  },
]

export function SectionOS() {
  const [{ configuration: state }, dispatch] = useAppState()
  const showSkeleton = state.isLoading || state.isError === 'api-fetch' || state.isError === 'management'

  const images = useStaticQuery(graphql`{
    windows: file(relativePath: {eq: "server-os/windows-icon.png"}) {
      childImageSharp {
        gatsbyImageData(height: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    linux: file(relativePath: {eq: "server-os/linux-icon.png"}) {
      childImageSharp {
        gatsbyImageData(height: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }`)

  function handleOsTypeChange(os) {
    dispatch(actions.setOperatingSystemType(os))
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-6">Operating System</h3>

      <div className="grid md:grid-cols-2 gap-2 md:gap-4 xl:gap-6">
        {showSkeleton ? Array(2).fill().map((value, index) => (
          <SkeletonCard key={index} className="h-[66px] lg:h-[75px]" />
        )): null}
        {!state.isLoading && (state.isError === '' || state.isError === 'api-post') && OS_TYPES.map((type) => (
          <CardButtonBase
            key={type.id}
            className="items-center"
            isSelected={type.id === state.operatingSystemType}
            onClick={() => handleOsTypeChange(type.id)}
          >
            <div className="w-10 md:w-auto flex justify-center">
              <GatsbyImage
                image={images[type.id].childImageSharp.gatsbyImageData}
                alt={`${type.name} Logo`}
              />
            </div>
            <h4 className="text-2xl font-normal my-0 ml-4">{type.name}</h4>
          </CardButtonBase>
        ))}
      </div>
    </div>
  )
}
