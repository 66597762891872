import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export function NavigationItem({
  label,
  isActive,
  onClick
}) {
  if (typeof label !== 'string') {
    return;
  }

  return (
    <button 
      onClick={onClick}
      className={
        classnames(
          'rounded-full', 
          'text-black',
          'px-4',
          'py-2',
          {
            'hover:bg-lw-ui-light': !isActive,
            'focus:bg-lw-ui-light': !isActive,
            'bg-lw-white': !isActive,
            'bg-lw-black': isActive,
            'text-lw-white': isActive,
          }
        )
      }
    >
      {label}
    </button>
  )
}

NavigationItem.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

