import React from 'react'

import { useAppState } from '../../../state'
import actions from '../../../state/configuration/actions'

import { CardButtonBase } from '../common/card/CardButtonBase'
import { Chip } from '../common/chip'
import { SkeletonCard } from '../common/skeleton-card'
import CloudMetalLogo from '../../../images/cloud-metal.inline.svg'
import CloudVpsLogo from '../../../images/cloud-vps.inline.svg'

export const SERVER_TYPES = [
  {
    id: 'vps',
    name: 'Cloud VPS',
    description: 'Single-tenant cloud environments offer the perfect blend of control and affordability.',
    cost: 'Starting At $5 Monthly',
  },
  {
    id: 'metal',
    name: 'Cloud Metal',
    description: 'High-performance single-tenant servers plus our powerhouse platform.',
    cost: 'Starting At $115 Monthly',
  },
]

export function SectionServerType() {
  const [{ configuration: state }, dispatch] = useAppState()

  function handleServerTypeSelection(typeId) {
    dispatch(actions.setHardwareTab(typeId === 'metal' ? 'bare-metal' : 'gp'))
    dispatch(actions.setServerType(typeId))
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-6">Server Type</h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-2 sm:gap-6">
        {state.isLoading || state.isError === 'api-fetch' ? Array(2).fill().map((value, index) => (
          <SkeletonCard key={index} className="h-[178px] lg:h-[292px]" />
        )): null}
        {!state.isLoading && state.isError !== 'api-fetch' && SERVER_TYPES.map((type) => (
            <CardButtonBase
              key={type.id}
              className="!p-6 lg:!p-4 xl:!p-6"
              isSelected={type.id === state.serverType}
              onClick={() => handleServerTypeSelection(type.id)}
            >
              <div className="flex flex-row-reverse sm:flex-col gap-4 sm:gap-0 items-start">
                <div className="flex justify-end grow-1 shrink-0 basis-16 sm:basis-0">
                  {type.id === 'metal' ? <CloudMetalLogo /> : <CloudVpsLogo />}
                </div>
                <div className="">
                  <h4 className="text-2xl font-normal mt-0 sm:mt-4 mb-2">{type.name}</h4>
                  <p className="text-base text-lw-text-disabled mb-4">{type.description}</p>
                  <Chip>{type.cost}</Chip>
                </div>
              </div>
            </CardButtonBase>
          ))
        }
      </div>
    </div>
  )
}
