import React from 'react'
import PropTypes from 'prop-types'
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import useSeo from '../hooks/useSeo'

const queryClient = new QueryClient()

function ConfigurationLayout({ children }) {
  const seo = useSeo()

  return (
    <QueryClientProvider client={queryClient}>
      <SEOContext.Provider value={{ global: seo }}>
        {children}
      </SEOContext.Provider>
    </QueryClientProvider>
  )
}

ConfigurationLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ConfigurationLayout
