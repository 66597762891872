import axios from 'axios'
import { useQueries } from '@tanstack/react-query'

import { CART_DETAILS_URL, BASIC_AUTH, CLOUD_PRODUCTS } from '../components/configuration/constants'

export function useProductDetails() {
  async function fetchProductDetailsByCode(productCode) {
    const { data } = await axios.get(
      CART_DETAILS_URL,
      {
        params: {
          product_code: productCode
        },
        auth: BASIC_AUTH
      }
    )

    return data
  }

  const queries = useQueries({
    queries: CLOUD_PRODUCTS.map((productCode) => {
      return {
        queryKey: ['productDetails', productCode],
        queryFn: async () => await fetchProductDetailsByCode(productCode)
      }
    })
  })

  return queries.reduce((acc, details, idx) => {
    const productCode = CLOUD_PRODUCTS[idx]
    acc[productCode] = details

    return acc
  }, {})
}
