import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { InView } from 'react-intersection-observer'

import { useProductDetails } from '../../hooks/useProductDetails'
import { useScrollBlock } from '../../hooks/useScrollBlock'
import { useAppState } from '../../state'
import actions from '../../state/configuration/actions'
import scrollToElement from '../../utils/scrollToElement'

import ConfigurationLayout from '../../components/configuration-layout'
import { Navigation } from '../../components/configuration/navigation'
import { FloatingCart } from '../../components/configuration/floating-cart'
import { Summary } from '../../components/configuration/summary'
import {
  SectionServerType,
  SectionServerLocation,
  SectionHardware,
  SectionOS,
  SectionControlPanel,
  SectionManagement,
  SectionTemplate,
  SectionWindowsAddOns,
  SectionIpAddresses,
  SectionBlockStorage,
  SectionBackUps,
  SectionProtection,
} from '../../components/configuration/section'
import {
  PRODUCT_VPS_LIN,
  PRODUCT_VPS_WIN,
  PRODUCT_METAL_LIN,
  PRODUCT_METAL_WIN,
  ERROR_API_FETCH,
  ROOT_MARGIN,
  SCROLL_OFFSET,
  CONFIGURATION_SECTIONS,
} from '../../components/configuration/constants'

function VPSConfigurationPage() {
  return (
    <ConfigurationLayout>
      <App />
    </ConfigurationLayout>
  )
}

const App = () => {
  const serverTypeRef = useRef()
  const locationRef = useRef()
  const osRef = useRef()
  const managementRef = useRef()
  const templateControlsRef = useRef()
  const addOnsRef = useRef()

  const [activeSection, setActiveSection] = useState('server-type')
  const [summaryPanelOpen, setSummaryPanelOpen] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()
  const [{ configuration: state }, dispatch] = useAppState()
  const productData = useProductDetails()
  const { isLoading, isError } = productData[state.productCode]

  useEffect(() => {
    dispatch(actions.setIsLoading(isLoading))
    dispatch(actions.setIsError(isError ? 'api-fetch' : ''))
    dispatch(actions.setErrorMessage(isError ? ERROR_API_FETCH : ''))
  }, [dispatch, isLoading, isError])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const type = params !== '' ? params.get('type') : 'vps'

    dispatch(actions.setServerType(type && type === 'metal' ? 'metal' : 'vps'))
    dispatch(actions.setHardwareTab(type && type === 'metal' ? 'bare-metal' : 'gp'))
  }, [dispatch])
  
  useEffect(() => {
    let productCode = PRODUCT_VPS_LIN
    const isWindows = state.operatingSystemType === 'windows'

    if (state.serverType === 'metal') {
      productCode = isWindows ? PRODUCT_METAL_WIN : PRODUCT_METAL_LIN
    } else {
      productCode = isWindows ? PRODUCT_VPS_WIN : PRODUCT_VPS_LIN
    }

    dispatch(actions.setProductCode(productCode))
  }, [dispatch, state.serverType, state.operatingSystemType])

  function handleNavigationClick(id) {
    setActiveSection(id)
    setSummaryPanelOpen(false)
    allowScroll()

    switch(id) {
      case 'server-type':
        scrollToElement(serverTypeRef.current, SCROLL_OFFSET)
        return;
      case 'location':
        scrollToElement(locationRef.current, SCROLL_OFFSET)
        return;
      case 'os':
        scrollToElement(osRef.current, SCROLL_OFFSET)
        return;
      case 'management':
        scrollToElement(managementRef.current, SCROLL_OFFSET)
        return;
      case 'template-controls':
        scrollToElement(templateControlsRef.current, SCROLL_OFFSET)
        return;
      case 'add-ons':
        scrollToElement(addOnsRef.current, SCROLL_OFFSET)
        return;
    }
  }

  function handleSectionVisible(isVisible, sectionId) {
    if (isVisible && sectionId) {
      setActiveSection(sectionId)
    }
  }

  function handleSummaryPanelChange(isOpen) {
    setSummaryPanelOpen(isOpen)
    isOpen ? blockScroll() : allowScroll()
  }

  return (
    <div className="font-roobert bg-lw-off-white">
      <Navigation activeSection={activeSection} sections={CONFIGURATION_SECTIONS} onClick={handleNavigationClick} />
      <div className="grid lg:grid-cols-2 gap-[60px]">
        <div className={classnames(
          'fixed',
          'top-[72px]',
          'z-10',
          'w-full',
          'lg:sticky',
          'lg:top-0',
          'self-start',
          'lg:h-screen',
          'bg-lw-ui-light',
          'flex justify-end',
          'p-0',
          'lg:pt-[150px]',
          'lg:pr-[80px]',
          'lg:pl-8',
          'lg-reduced-height:pt-[115px]',
          'group-[.noscroll]/body:overflow-y-scroll',
          'group-[.noscroll]/body:h-[calc(100dvh-125px)]',
          'group-[.noscroll]/body:lg:!h-screen',
          {
            'lg:pt-[210px]': isError
          }
        )}>
          <div className={classnames(
            'w-full',
            'md:max-w-3xl',
            'lg:max-w-lg',
            'mx-auto',
            'lg:mx-0',
          )}>
            <Summary
              isSummaryOpen={summaryPanelOpen}
              setSummaryOpen={handleSummaryPanelChange}
              locationRef={locationRef.current}
              serverTypeRef={serverTypeRef.current}
              templateControlsRef={templateControlsRef.current}
              managementRef={managementRef.current}
              addOnsRef={addOnsRef.current}
            />
          </div>
        </div>
        <div className={classnames(
          'px-4',
          'sm:px-8',
          'lg:pl-0',
          'lg-reduced-height:pt-[115px]',
          'py-[150px]',
          {
            'pt-[210px]': isError
          }
        )}>
          <div className={classnames(
            'w-full',
            'md:max-w-2xl',
            'lg:max-w-[37rem]',
            'mx-auto',
            'lg:mx-0',
            'flex',
            'flex-col',
            'gap-20',
            'lg:gap-28'
          )}>
            <InView rootMargin={ROOT_MARGIN} onChange={(inView) => handleSectionVisible(inView, 'server-type')}>
              <div ref={serverTypeRef}>
                <SectionServerType />
              </div>
            </InView>

            <InView rootMargin={ROOT_MARGIN} onChange={(inView) => handleSectionVisible(inView, 'location')}>
              <div ref={locationRef}>
                <SectionServerLocation />
              </div>
            </InView>

            <InView rootMargin={ROOT_MARGIN} onChange={(inView) => handleSectionVisible(inView, 'os')}>
              <div ref={osRef}>
                <SectionOS operatingSystems={[{foo: 'bar'}]} />
              </div>
            </InView>

            <InView rootMargin={ROOT_MARGIN} onChange={(inView) => handleSectionVisible(inView, 'management')}>
              <div ref={managementRef}>
                <SectionManagement />
                <SectionTemplate />
              </div>
            </InView>

            <InView rootMargin={ROOT_MARGIN} onChange={(inView) => handleSectionVisible(inView, 'template-controls')}>
              <div ref={templateControlsRef} className="flex flex-col gap-28">
                <SectionControlPanel controlPanels={[{foo: 'bar'}]} />
                <SectionHardware />
              </div>
            </InView>

            <InView rootMargin={ROOT_MARGIN} onChange={(inView) => handleSectionVisible(inView, 'add-ons')}>
              <div ref={addOnsRef} className="flex flex-col gap-28">
                <SectionWindowsAddOns />
                <SectionBlockStorage />
                <SectionBackUps />
                <SectionIpAddresses />
                <SectionProtection />
              </div>
            </InView>
          </div>
        </div>

      </div>
      <FloatingCart />
    </div>
  )
}

VPSConfigurationPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default VPSConfigurationPage;
