import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { orderBy } from 'lodash'

import { useAppState } from '../../../state'
import actions from '../../../state/configuration/actions'
import { useProductDetails } from '../../../hooks/useProductDetails'
import { configurationHelper } from '../../../utils/configurationHelper'

import { SelectableCard } from '../common/selectable-card'
import { SkeletonCard } from '../common/skeleton-card'
import { LOCATION_MAP } from '../constants'

export function SectionServerLocation() {
  const [{ configuration: state }, dispatch] = useAppState()
  const productData = useProductDetails()
  const { status, data } = productData[state.productCode]
  const { getRegions } = configurationHelper(data)
  const showSkeleton = state.isLoading || state.isError === 'api-fetch'

  const locations = useMemo(() => {
    if (status === 'success') {
      const regions = getRegions()
      if (regions) {

        return orderBy(regions, ['region_id'], ['asc'])
      }
    }

    return null
  }, [status, getRegions])

  const images = useStaticQuery(graphql`{
    usa: file(relativePath: {eq: "flags/usa.png"}) {
      childImageSharp {
        gatsbyImageData(width: 34, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    nl: file(relativePath: {eq: "flags/nl.png"}) {
      childImageSharp {
        gatsbyImageData(width: 34, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }`)

  function handleLocationSelection(location) {
    dispatch(actions.setServerZoneId(location.zone_id))
    dispatch(actions.setServerRegionId(location.region_id))
    dispatch(actions.setServerLocation(location.name))
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-6">Your Server Location</h3>

      <div className="flex flex-col gap-2 lg:gap-4">
        {showSkeleton ? Array(3).fill().map((value, key) => (
          <SkeletonCard key={key} className="h-[58px] lg:h-[66px]" />
        )) : null}
        {!state.isLoading && state.isError !== 'api-fetch' && locations && locations.map((location) => {
          if (!LOCATION_MAP.get(location.name)) {
            return <></>
          }

          const {
            city,
            region,
            country,
            countryCode
          } = LOCATION_MAP.get(location.name)

          return (
            <SelectableCard
              key={location.region_id}
              title={<div className="text-2xl">{city}</div>}
              subheader={<div className="text-xs text-lw-text-disabled">{region}</div>}
              value={location.name}
              isSelected={state.serverLocation === location.name}
              startElement={
                countryCode && images?.[countryCode]?.childImageSharp?.gatsbyImageData ? (
                  <GatsbyImage image={images[countryCode].childImageSharp.gatsbyImageData} alt={`${country} Flag`} />
                ) : null
              }
              endElement={<div className="text-lw-text-disabled">{country}</div>}
              onClick={() => handleLocationSelection(location)}
            />
          )
        })}
      </div>

    </div>
  )
}
