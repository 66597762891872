import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { inRange } from 'lodash'

const AddIcon = ({className}) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </g>
  </svg>
)

const RemoveIcon = ({className}) => (
  <svg className={className} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="m19 13h-14v-2h14z" />
  </svg>
)

export function QuantityControl({
  controlElemClasses,
  wrapperElemClasses,
  labelElemClasses,
  inputWrapperElemClasses,
  iconElemClasses,
  buttonElemClasses,
  size = 'lg',
  value,
  max,
  min,
  label,
  inputPrefix,
  inputSuffix,
  inputRef,
  onChange,
  children
}) {
  const [internalValue, setInternalValue] = useState()
  const [decrementDisabled, setDecrementDisabled] = useState(false);
  const [incrementDisabled, setIncrementDisabled] = useState(false);

  const valueDisplayRef = useRef();

  const controlClasses = classnames(
    'flex',
    'items-center',
    controlElemClasses
  )
  const wrapperClasses = classnames(
    'flex',
    'border',
    'border-lw-ui-border',
    'focus-within:border-lw-ui-border-hover',
    'rounded-lg',
    {
      'lg:px-3': size === 'sm',
      'w-full': size !== 'sm',
    },
    wrapperElemClasses
  )
  const labelClasses = classnames(
    'text-sm',
    'font-medium',
    labelElemClasses,
  )
  const inputWrapperClasses = classnames(
    'flex',
    'justify-center',
    'items-center',
    'gap-6',
    'grow',
    {
      'py-4': size === 'lg',
      'lg:py-6': size === 'lg',
      'p-2': size === 'xs',
      'lg:p-2': size === 'xs',
      'text-2xl': size === 'sm',
      'lg:text-3xl': size === 'sm',
      'lg:px-3': size === 'sm',
      'text-base': size === 'xs',
    },
    inputWrapperElemClasses
  )
  const iconClasses = classnames(
    'bg-lw-white',
    'group-hover:bg-lw-ui-dark',
    'fill-lw-text-disabled',
    'group-hover:fill-lw-white',
    'rounded-full',
    'transtion-all',
    'group-disabled:!bg-lw-white',
    'group-disabled:!fill-lw-text-disabled',
    {
      'w-[18px]': size === 'sm',
      'sm:w-6': size === 'sm',
    },
    iconElemClasses
  )
  const buttonClasses = classnames(
    'group', 
    {
      'p-4': size === 'lg',
      'lg:p-6': size === 'lg',
      'p-2': size === 'sm',
      'lg:p-3': size === 'sm',
      'lg:p-2': size === 'xs',
    },
    buttonElemClasses
  )

  useEffect(() => {
    setInternalValue(value)

    if (typeof min !== 'undefined' && typeof value !== 'undefined') {
      setDecrementDisabled(Number(value) <= Number(min))
    }

    if (typeof max !== 'undefined' && typeof value !== 'undefined') {
      setIncrementDisabled(Number(value) >= Number(max))
    }
  }, [value, min, max, valueDisplayRef])

  function handleDecrement() {
    onChange(Number(internalValue) - 1) 
  }
  
  function handleIncrement() {
    onChange(Number(internalValue) + 1) 
  }
  
  return (
    <div className={controlClasses}>
      { 
        label && typeof label === 'string' ? (
          <label className={labelClasses}>{ label }</label>
        ) : null
      }
      <div className={wrapperClasses}>
        <button
          disabled={decrementDisabled}
          className={buttonClasses}
          onClick={handleDecrement}
        >
          <RemoveIcon className={iconClasses} />
        </button>
        <div className={inputWrapperClasses}>
          {children ? children : (
            <>
              {inputPrefix ? inputPrefix : null}
              <span className="relative">
                <span ref={valueDisplayRef} aria-hidden="true" className="px-2">
                  {value}
                </span>
                <input
                  ref={inputRef}
                  className={classnames(
                    'absolute',
                    'p-0',
                    'border-none',
                    'left-0',
                    'w-full',
                    'h-full',
                    'text-center',
                    '[appearance:textfield]',
                    '[&::-webkit-outer-spin-button]:appearance-none',
                    '[&::-webkit-inner-spin-button]:appearance-none',
                    'outline-none',
                  )}
                  type="number"
                  value={value}
                  onChange={(event) => {
                    if (typeof min !== 'undefined' && typeof max !== 'undefined') {
                      if (inRange(event.target.value, min, (Number(max) + 1))) {
                        onChange(event.target.value)
                      }
                    } else {
                      onChange(event.target.value)
                    }
                  }}
                />
              </span>
              {inputSuffix ? inputSuffix : null}
            </>
          )}
        </div>
        <button
          disabled={incrementDisabled}
          className={buttonClasses}
          onClick={handleIncrement}
        >
          <AddIcon className={iconClasses} />
        </button>
      </div>
    </div>
  )
}

QuantityControl.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  inputPrefix: PropTypes.string,
  inputSuffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  size: PropTypes.oneOf(['xs','sm','lg']),
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}

