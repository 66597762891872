import React, { useEffect, useMemo } from 'react'
import classnames from 'classnames'

import { removeDecimalIfWhole } from '../../../utils/removeDecimalIfWhole'
import { useAddToCart } from '../../../hooks/useAddToCart'
import { useAppState } from '../../../state'
import actions from '../../../state/configuration/actions'

import { SkeletonCard } from '../common/skeleton-card'
import { QuantityControl } from '../quantity-control'
import { CART_REDIRECT_URL, ERROR_API_POST } from '../constants'

const SERVER_QTY_LABEL = 'Servers Quantity'
const BASE_RESPONSE = {
  product_code: null,
  region_id: null,
  zone_id: null,
  config_id: null,
  template: null,
  ddos: null,
  extra_ip: null,
  use_ipv6: 0,
  quantity: 1,
  backups: {
    name: 'per_gb'
  },
  block_storage: {
    name: 'none',
  },
}

export function FloatingCart() {
  const [{
    configuration: configState,
    summary: summaryState
  }, dispatch] = useAppState()
  const { addToCart } = useAddToCart()
  const isWindows = configState.operatingSystemType === 'windows'
  const isCpanel = summaryState?.controlPanel?.value === 'cPanel'
  const isDisabled = configState.isLoading || configState.isError || addToCart.isLoading || configState.templateValue === ''

  useEffect(() => {
    if (addToCart.status === 'success' && addToCart?.data?.cart_uuid) {
      window.location.assign(`${CART_REDIRECT_URL}${addToCart.data.cart_uuid}`)
    }

    if (addToCart.isError || addToCart?.data?.errors?.length) {
      dispatch(actions.setIsError('api-post'))
      dispatch(actions.setErrorMessage(ERROR_API_POST))
    }
  }, [addToCart.status, addToCart.data])

  const configSum = useMemo(() => {
    let sum = 0
    const {hardwareDetails, ...rest} = summaryState

    Object.keys(rest).forEach((key) => {
      sum += Number(summaryState[key].cost)
    })

    return sum
  }, [summaryState])

  const cartSum = !isNaN(configSum) ? removeDecimalIfWhole(configSum * configState.serverQty) : 0

  function prepareCartData() {
    const blockStorage = configState.blockStorage === 0
      ? {name: 'none'}
      : {name: 'per_gb', gb: String(configState.blockStorage)}
    const backups = configState.backupsEnabled
      ? {name: 'per_gb'}
      : {name: 'none'}
    const extraIp = typeof configState.ipAddresses === 'number'
      ? configState.ipAddresses - 1
      : 0
    const cPanelLicense = !isWindows && isCpanel
      ? {cpanel_license: configState.cPanelLicenseTier}
      : null
    const windowsAddOns = isWindows
      ? {
        win_av: configState.windowsAntiVirus,
        mssql: configState.windowsMsSQL,
      }
      : null

    return Object.assign({},
      BASE_RESPONSE,
      {
        quantity: configState.serverQty,
        product_code: configState.productCode,
        template: configState.templateValue,
        region_id: Number(configState.serverRegionId),
        zone_id: Number(configState.serverZoneId),
        ddos: configState.ddosOption,
        config_id: configState.hardwareOption,
        extra_ip: extraIp,
        use_ipv6: configState.addIPv6 ? 1 : 0,
        block_storage: blockStorage,
        backups
      },
      cPanelLicense,
      windowsAddOns,
    )
  }

  function sendToGoogleTagManager(total_cost, product_code, product_cost, product_qty) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'lw.ecommerce',
      ecommerceType: 'ga4',
      data: {
        category: 'Ecommerce',
        action: 'basketItem_added',
      },
      ecommerceEventName: 'add_to_cart',
      ecommerce: {
        currency: 'USD',
        value: total_cost ? String(total_cost) : '',
        items: [
          {
            item_name: product_code,
            price: product_cost ? String(product_cost) : '',
            quantity: product_qty ? String(product_qty) : '',
            item_brand: 'Liquid Web',
          },
        ],
      },
    })
  }

  function handlePurchaseClick() {
    const preparedData = prepareCartData()
    addToCart.mutate(preparedData)

    console.group('Buy & Deploy: Payload')
    console.log(preparedData)
    console.groupEnd()

    sendToGoogleTagManager(cartSum, configState.productCode, configSum, configState.serverQty)
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 w-full">
      <div className="mx-auto max-w-[90rem]">
        <div className="lg:mb-6 lg:mx-6 lg:rounded-md flex items-center justify-between gap-4 bg-white lg:shadow-metal border border-t-lw-ui-border lg:border-0 px-4 lg:pl-6 lg:pr-2 py-2">
          {configState.isLoading || configState.isError === 'api-fetch' ? (
            <div className="flex items-center">
              <span className="text-sm font-medium mr-4 hidden lg:block">{SERVER_QTY_LABEL}</span>
              <SkeletonCard className="w-[172px] h-[50px]" />
            </div>
          ) : (
            <QuantityControl
              labelElemClasses="mr-4 hidden lg:block"
              size="sm"
              label={SERVER_QTY_LABEL}
              value={configState.serverQty}
              min={1}
              max={10}
              onChange={(value) => dispatch(actions.setServerQty(value))}
            />
          )}
          <div className="flex items-center gap-4">
            <span className="text-base sm:text-2xl lg:text-3xl">
              {!configState.isLoading && !configState.isError !== 'api-fetch' && !isNaN(cartSum) ? `$${cartSum} Monthly` : null}
            </span>
            <button
              className={classnames(
                'lg:ml-6',
                'px-3',
                'sm:px-4',
                'py-2',
                'lg:px-7',
                'lg:py-3',
                'rounded',
                'bg-lw-ui-green',
                'hover:bg-lw-ui-green-dark',
                'text-white',
                'text-sm',
                'transition-all',
                {
                  '!bg-lw-disabled': isDisabled,
                  '!text-lw-text-disabled': isDisabled,
                }
              )}
              onClick={handlePurchaseClick}
              disabled={isDisabled ? true : null}
            >
              {configState.isLoading || addToCart.isLoading ? 'Loading...' : 'Buy & Deploy'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
