import axios from 'axios'
import { useMutation } from '@tanstack/react-query'

import { ADD_TO_CART_URL, BASIC_AUTH } from '../components/configuration/constants'

export function useAddToCart() {

  async function createCart(data) {
    return axios
    .post(ADD_TO_CART_URL, {params: data}, {auth: BASIC_AUTH})
    .then(res => res.data)
  }

  const addToCart = useMutation({
    mutationFn: createCart,
  })

  return {
    addToCart
  }
}
