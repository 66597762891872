import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export function ButtonPill({
  className,
  active,
  disabled,
  onClick,
  children
}) {
  const buttonClasses = classnames(
    'rounded-full',
    'border',
    'border-lw-ui-border',
    'transition-all',
    'px-4',
    'py-2',
    'disabled:bg-lw-disabled',
    'disabled:border-lw-disabled',
    'disabled:text-lw-text-disabled',
    {
      'text-white': active,
      'hover:border-lw-fill-disabled': disabled,
      'hover:border-lw-ui-border-hover': !active,
      'hover:bg-lw-ui-light': !active,
      'border-lw-ui-dark': active,
      'bg-lw-ui-dark': active,
      'hover:bg-lw-ui-dark': active,
    },
    className
  )

  return (
    <button
      disabled={disabled}
      className={buttonClasses}
      onClick={onClick}
    >{children}</button>
  )
}

ButtonPill.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
}

