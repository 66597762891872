import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { CardButtonBase } from '../common/card/CardButtonBase'
import { Chip } from '../common/chip'
import { VPS_MAP, METAL_MAP } from '../constants'
import BoltIcon from '../../../images/icon-bolt.inline.svg'
import RaidIcon from '../../../images/icon-raid.inline.svg'

export function HardwareCard({
  cost,
  billingCycle,
  isSelected,
  disabled,
  onClick,
  serverType = 'vps',
  data
}) {
  const diskType = data?.disk_type ? ` ${data.disk_type}` : ''
  const raidLevel = data?.raid_level ? ` RAID-${data.raid_level}` : ''
  const chipClasses = classnames(
    {
      'bg-lw-disabled': disabled,
      'border': disabled,
      'border-lw-ui-border-disabled': disabled,
      'grow-1': serverType === 'metal',
      'shrink-0': serverType === 'metal',
      'basis-auto': serverType === 'metal',
    }
  )
  const cardHeaderClasses = classnames(
    'flex',
    'w-full',
    'mb-2',
    'gap-2',
    {'items-center': serverType !== 'metal'},
    serverType === 'metal' && [
      'items-start',
      'justify-between',
      'lg:flex-col-reverse',
      'lg:items-start',
      'xl:flex-row',
    ]
  )

  function getHardwareList() {
    const map = serverType === 'metal' ? METAL_MAP : VPS_MAP
    let list = []
    map.forEach((item, index) => {
      list.push(
        <React.Fragment key={index}>
          <dt>{item.label}</dt>
          <dd className="text-right">{`${data[index]}${item.suffix ? ` ${item.suffix}` : ''}`}</dd>
        </React.Fragment>
      )
    })

    return list
  }

  return (
    <CardButtonBase
      className={classnames(
        serverType === 'metal' && [
          'lg:items-start',
          'xl:items-center',
        ]
      )}
      disabled={disabled}
      isSelected={isSelected}
      onClick={onClick}
    >
      <div className="flex flex-col w-full">
        <div className={cardHeaderClasses}>
          {serverType === 'metal' ? (
            <span className="text-xl">
              {data?.cpu_model ? `${data.cpu_model}` : ''}
            </span>
          ) : null}
          <Chip className={chipClasses}>
            {`$${cost} ${billingCycle}`}
          </Chip>
        </div>
        {serverType === 'metal' ? (
          <div className="flex items-center gap-2 text-black text-xs mb-5">
            <span className="flex gap-[2px]">
              <BoltIcon />
              {diskType}
            </span>
            <span className="flex gap-[2px]">
              <RaidIcon />
              {raidLevel}
            </span>
          </div>
        ) : null}
        <dl className="grid grid-cols-2 text-lw-text-disabled text-sm">
          {getHardwareList()}
        </dl>
      </div>
    </CardButtonBase>
  )
}

HardwareCard.propTypes = {
  cost: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  billingCycle: PropTypes.string,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  serverType: PropTypes.oneOf(['vps','metal']),
  data: PropTypes.object.isRequired
}

