import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const CardButtonBase = forwardRef(function CardButtonBase({
  className,
  isSelected,
  disabled,
  onClick,
  children
}, ref) {
  const [effect, setEffect] = useState(false)
  const cardClasses = classnames(
    'flex',
    'items-center',
    'px-4',
    'py-3',
    'lg:p-4',
    'transition-all',
    'text-left',
    'border',
    'rounded-lg',
    'bg-lw-off-white',
    'disabled:bg-lw-disabled',
    'disabled:border-lw-disabled',
    'disabled:text-lw-text-disabled',
    isSelected && [
      'border-lw-green',
      'hover:border-lw-green',
      'shadow-metal',
      'bg-white',
    ],
    !isSelected && [
      'focus:border-lw-ui-border-hover',
      'focus-within:border-lw-ui-border-hover',
      'hover:bg-white',
    ],
    disabled && [
      'bg-lw-disabled',
      'border-lw-disabled',
      'hover:border-lw-disabled',
      'hover:shadow-none',
      'text-lw-text-disabled',
    ],
    !isSelected && !disabled && [
      'border-lw-ui-border',
      'hover:border-lw-ui-border-hover',
    ],
    effect && [
      'animate-shadow-pulse'
    ],
    className
  )

  return (
    <button
      ref={ref}
      className={cardClasses}
      disabled={disabled}
      onClick={() => {
        setEffect(true)
        onClick()
      }}
      onAnimationEnd={() => setEffect(false)}
    >{children}</button>
  )
})

CardButtonBase.propTypes = {
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

