import React, { useEffect, useRef, useState, useMemo } from 'react'
import classnames from 'classnames'
import { find, inRange } from 'lodash'

import { useAppState } from '../../../state'
import configActions from '../../../state/configuration/actions'
import summaryActions from '../../../state/summary/actions'
import { useProductDetails } from '../../../hooks/useProductDetails'
import { configurationHelper } from '../../../utils/configurationHelper'
import { removeDecimalIfWhole } from '../../../utils/removeDecimalIfWhole'

import { Picker, PickerItem } from '../picker'
import { QuantityControl } from '../quantity-control'
import { Chip } from '../common/chip'
import { Select } from '../common/select'
import { SkeletonCard } from '../common/skeleton-card'

const MIN_STORAGE = 0;
const MAX_STORAGE = 5000;
const STORAGE_PRESETS = [
  {
    value: 0,
    label: 'None'
  },
  {
    value: 10,
    label: '10 GB'
  },
  {
    value: 50,
    label: '50 GB'
  },
  {
    value: 100,
    label: '100 GB'
  },
  {
    value: 250,
    label: '250 GB'
  },
  {
    value: 500,
    label: '500 GB'
  },
  {
    value: 1000,
    label: '1 TB'
  },
  {
    value: 5000,
    label: '5 TB'
  },
]

export function SectionBlockStorage() {
  const inputRef = useRef();
  const [{ configuration: state }, dispatch] = useAppState()
  const [hasSelected, setHasSelected] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [monthlyCost, setMonthlyCost] = useState(0);
  const productData = useProductDetails()
  const { status, data } = productData[state.productCode]
  const { getDataByRegion } = configurationHelper(data)
  const showSkeleton = state.isLoading || state.isError === 'api-fetch' || state.isError === 'management'

  const blockStorageCost = useMemo(() => {
    if (status === 'success') {
      const { block_storage } = getDataByRegion(state.serverLocation)
      const perGb = find(block_storage, {'name': 'per_gb'})

      if (!perGb?.per_gb_price) {
        return false
      }

      return getMonthlyCost(perGb.per_gb_price)
    }
  }, [status, state.serverLocation, getDataByRegion])

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { 'unit': 'month' })

    return priceObject?.amount ? Number(priceObject.amount) : 0.10
  }

  useEffect(() => {
    dispatch(summaryActions.setBlockStorage({
      value: state.blockStorage === 0 ? 'None' : `${state.blockStorage} GB`,
      cost: removeDecimalIfWhole(monthlyCost)
    }))
  }, [monthlyCost, state.blockStorage])

  useEffect(() => {
    const valueIsPreset = STORAGE_PRESETS.filter((preset) => preset.value === state.blockStorage).length === 1
    setIsCustom(!valueIsPreset)
    setMonthlyCost(
      Math.round((state.blockStorage * blockStorageCost) * 100) / 100
    )
  }, [blockStorageCost, state.blockStorage])

  function handleMobileSelection({ target: { value }}) {
    if (value !== 'custom') {
      dispatch(configActions.setBlockStorage(Number(value)))
    }
  }

  function getFooterDisplay() {
    return (
      <div className="flex justify-between items-center">
        <QuantityControl
          size="xs"
          min={MIN_STORAGE}
          max={MAX_STORAGE}
          wrapperElemClasses={hasSelected ? 'bg-lw-off-white' : null}
          inputWrapperElemClasses="!gap-0"
          inputRef={inputRef}
          inputSuffix={
            <span className="text-lw-text-disabled">GB</span>
          }
          value={state.blockStorage}
          onChange={(value) => {
            setHasSelected(true)
            const intValue = Number.isInteger(value)
              ? value
              : Math.ceil(value)
            dispatch(configActions.setBlockStorage(intValue))
          }}
        />
        <Chip>{`+$${removeDecimalIfWhole(monthlyCost)} Monthly`}</Chip>
      </div>
    )
  }

  const pickerClasses = classnames(
    'hidden',
    'md:block',
    {
      '!border-lw-ui-green': hasSelected,
      '!bg-white': hasSelected,
    }
  )

  if (!blockStorageCost) {
    return <></>
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Cloud Block Storage <span className="font-normal text-sm text-lw-text-disabled">(Optional)</span></h3>
      <p className="mb-6">Physical components powering servers for data processing and storage.</p>

      <div className="md:hidden">
        {showSkeleton ? (
          <>
            <SkeletonCard className="h-[50px] mb-6" />
            <SkeletonCard className="h-[66px]" />
          </>
        ) : null}
        {!state.isLoading && !state.isError ? (
          <>
            <Select
              controlElemClass="mb-6"
              onChange={handleMobileSelection}
              value={isCustom ? 'custom' : state.blockStorage}
            >
              { STORAGE_PRESETS.map((preset, index) => (
                <option key={index} value={preset.value}>{preset.label}</option>
              )) }
              <option value="custom">Custom</option>
            </Select>
            <QuantityControl
              wrapperElemClasses="!bg-white"
              inputWrapperElemClasses="!gap-0"
              value={state.blockStorage}
              inputRef={inputRef}
              size="lg"
              min={MIN_STORAGE}
              max={MAX_STORAGE}
              inputSuffix={
                <span className="text-lw-text-disabled">GB</span>
              }
              onChange={(value) => {
                setHasSelected(true)
                dispatch(configActions.setBlockStorage(value))
              }}
            >
              <>
                <span className="relative text-2xl">
                  <span aria-hidden="true" className="px-2">
                    {state.blockStorage}
                  </span>
                  <input
                    className={classnames(
                      'absolute',
                      'left-0',
                      'w-full',
                      'h-full',
                      'text-center',
                      '[appearance:textfield]',
                      '[&::-webkit-outer-spin-button]:appearance-none',
                      '[&::-webkit-inner-spin-button]:appearance-none',
                      'outline-none',
                      'bg-transparent',
                    )}
                    type="number"
                    value={state.blockStorage}
                    onChange={(event) => {
                      const value = Number.isInteger(event.target.value)
                        ? event.target.value
                        : Math.ceil(event.target.value)
                      if (inRange(value, MIN_STORAGE, MAX_STORAGE + 1)) {
                        dispatch(configActions.setBlockStorage(value))
                      }
                    }}
                  />
                </span>
                <span className="text-lw-text-disabled">GB</span>
                <Chip className="ml-6">{`+$${removeDecimalIfWhole(monthlyCost)} Monthly`}</Chip>
              </>
            </QuantityControl>
          </>
        ) : null}
      </div>

      {showSkeleton ? (
        <SkeletonCard className="h-[149px] hidden md:block" />
      ) : (
        <Picker footer={getFooterDisplay()} className={pickerClasses}>
          {
            STORAGE_PRESETS.map((preset) => (
              <PickerItem
                key={preset.value}
                isDefault={0 === preset.value && !hasSelected}
                active={state.blockStorage === preset.value}
                onClick={() => {
                  setHasSelected(true)
                  dispatch(configActions.setBlockStorage(preset.value))
                }}
              >
                <span className="text-xs">
                  {preset.label}
                </span>
              </PickerItem>
            ))
          }
          <PickerItem
            active={isCustom}
            onClick={() => {
              setHasSelected(true)
              inputRef.current.focus()
            }}
          >
            <span className="text-xs">
              Custom
            </span>
          </PickerItem>
        </Picker>
      )}
    </div>
  )
}
