export function removeDecimalIfWhole(price) {
  const priceNumber = Number(price)
  // Check if the price is a whole number (no decimal part)
  if (priceNumber === Math.floor(price)) {
    // If it's a whole number, convert it to an integer
    return priceNumber.toFixed(0)
  } else {
    // If it's not a whole number, return it as is
    return priceNumber.toFixed(2).toString()
  }
}
