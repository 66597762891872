import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'

export function Modal({
  isOpen,
  setIsOpen,
  title,
  children
}) {
  const closeButtonRef = useRef()

  useEffect(() => {
    if (closeButtonRef?.current && isOpen) {
      closeButtonRef.current.focus()
    }
  }, [closeButtonRef, isOpen])

  if (! children) {
    return <></>
  }

  const modalClasses = classnames(
    'fixed',
    'inset-0',
    'z-[100]',
    'flex',
    'flex-col',
    'justify-center',
    'align-center',
    {
      'hidden': !isOpen,
      'block': isOpen,
    }
  )
  const maskClasses = classnames(
    'fixed',
    'inset-0',
    'h-full',
    'bg-lw-blue-dark/75',
  )
  const buttonClasses = classnames(
    'p-1',
    'rounded',
    'border',
    'border-lw-ui-border',
    'text-lw-text-disabled',
    'hover:text-lw-black',
    'hover:border-lw-black',
    'transition-all',
  )

  return (
    <>
      <div className={modalClasses}>
        <div
          className="bg-white p-4 md:p-8 rounded-md w-[calc(100%_-_32px)] max-h-[calc(100%_-_32px)] max-w-4xl h-full md:h-auto shadow-2xl z-10"
          role="dialog"
          aria-labelledby="modal-title"
        >
          <div className="mb-8 flex justify-between items-center gap-4">
            <span id="modal-title" className="text-xl">{title ? title : ''}</span>
            <button
              className={buttonClasses}
              onClick={() => setIsOpen(false)}
              aria-label="Close"
              ref={closeButtonRef}
            >
              <span className="sr-only">Close Modal</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="max-w-full max-h-[calc(100%_-_66px)] overflow-auto">
            {children}
          </div>
        </div>
        <div className={maskClasses} onClick={() => setIsOpen(false)} />
      </div>
    </>
  )
}
