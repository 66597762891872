import React, { useEffect, useRef, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { find, uniq } from 'lodash'

import { configurationHelper } from '../../../utils/configurationHelper'
import { useAppState } from '../../../state'
import configActions from '../../../state/configuration/actions'
import summaryActions from '../../../state/summary/actions'

import { SelectableCard } from '../common/selectable-card'
import { SkeletonCard } from '../common/skeleton-card'
import { Select } from '../common/select'

export function SectionTemplate() {
  const cardRef = useRef({})
  const [defaultDistro, setDefaultDistro] = useState({
    id: '',
    distro: '',
    version: '',
    bit: '',
  })
  const [{ configuration: configState }, dispatch] = useAppState()
  const { sortByKey } = configurationHelper()
  const showSkeleton = configState.isLoading || configState.isError === 'api-fetch' || configState.isError === 'management'

  const images = useStaticQuery(graphql`{
    Alma: file(relativePath: {eq: "server-os/alma-linux.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    AlmaLinux: file(relativePath: {eq: "server-os/alma-linux.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Alpine: file(relativePath: {eq: "server-os/alpine.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Arch: file(relativePath: {eq: "server-os/arch.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    CentOS: file(relativePath: {eq: "server-os/cent-os.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    CloudLinux: file(relativePath: {eq: "server-os/cloud-linux.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Debian: file(relativePath: {eq: "server-os/debian.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Fedora: file(relativePath: {eq: "server-os/fedora.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Rocky: file(relativePath: {eq: "server-os/rocky-linux.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Ubuntu: file(relativePath: {eq: "server-os/ubuntu.png"}) {
      childImageSharp {
        gatsbyImageData(width: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
    Windows: file(relativePath: {eq: "server-os/windows-icon.png"}) {
      childImageSharp {
        gatsbyImageData(height: 40, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }`)

  const availableDistros = useMemo(() => {
    const distros = configState?.availableDistros && Object.keys(configState.availableDistros).length
      ? configState.availableDistros
      : false
    const nextDistros = []
    let defaultDistro = {}

    if (distros) {
      const distroKeys = Object.keys(distros)
      // Assign first distribution to default.
      defaultDistro = distros[distroKeys[0]][0]

      distroKeys.forEach((key) => {
        const versions = getAvailableDistributionVersions(key, distros)
        // Look for a distribution explicitly set as default
        const isDefaultDistro = find(distros[key], { 'default': 1 })
        
        // Replace defaultDistro with explicit default, if found.
        if (isDefaultDistro) {
          defaultDistro = isDefaultDistro
        }

        nextDistros.push({
          id: key,
          name: versions.length === 1 ? `${key} ${versions[0].value}` : key,
          bit: distros?.[key]?.[0]?.bit ? `${distros[key][0].bit}-bit` : '',
          versions: versions.length > 1 ? versions : versions[0],
        })
      })
          
      setDefaultDistro({
        id: `${configState.managementLevel}${defaultDistro.name}${defaultDistro.version}`,
        distro: defaultDistro.name,
        version: defaultDistro.version,
        bit: defaultDistro.bit
      })
    }

    return nextDistros

  }, [configState.availableDistros, configState.managementLevel])

  useEffect(() => {
    if (availableDistros) {
      const { distro, version, bit } = defaultDistro

      if (distro && version) {
        const id = `${configState.managementLevel}${distro.trim()}${version.trim()}`

        dispatch(summaryActions.setDistro({
          value: `${distro} ${version} ${bit ? `${bit} Bit` : ''}`
        }))
        dispatch(configActions.setOperatingSystemId(id))
        dispatch(configActions.setOperatingSystemDistro(distro))
        dispatch(configActions.setOperatingSystemVersion(version))
      }
    }
  }, [dispatch, defaultDistro, availableDistros, configState.managementLevel, configState.setOperatingSystemId, configState.productCode])

  function getAvailableDistributionVersions(distributionName, availableDistributions = []) {
    if (!distributionName) {
      return [] 
    }

    let versions = []
    availableDistributions[distributionName].forEach((distro) => {
      if (distro.version && distro.version !== '') {
        versions.push({
          order: distro.display_order,
          value: distro.version,
          default: distro.default ? true : false,
        })
      }
    })

    return uniq(versions).sort((a, b) => b.order + a.order)
  }

  function getDistroVersionSelectField(os) {
    const { versions } = os

    if (!Array.isArray(versions) || versions.length <= 0) {
      return <input type="hidden" value={versions.value} />
    }

    const sortedVersions = sortByKey(versions, 'order')

    return (
      <div className="xl:mr-4">
        <Select
          label={<label className="text-xs mr-2">Version</label>}
          controlElemClass="!flex-row items-center"
          selectWrapElemClass="!w-[92px] sm:!w-[160px]"
          size="sm"
          onClick={(event) => event.stopPropagation()}
          onChange={() => handleDistributionClick(cardRef.current[os.id], os.id, os.bit)}
          value={configState.operatingSystemVersion}
        >
          {
            sortedVersions.map((version, index) => (
              <option key={`${index}-${version.value}`} value={version.value}>{version.value}</option>
            ))
          }
        </Select>
      </div>
    )
  }

  function handleDistributionClick(ref, distro, bit) {
    const versionValue = ref.current.querySelector('input')
      ? ref.current.querySelector('input').value
      : ref.current.querySelector('select').value
    const id = `${configState.managementLevel}${distro.trim()}${versionValue.trim()}`

    dispatch(summaryActions.setDistro({
      value: `${distro} ${versionValue} ${bit ? `${bit} Bit` : ''}`
    }))
    dispatch(configActions.setOperatingSystemId(id))
    dispatch(configActions.setOperatingSystemVersion(versionValue))
    dispatch(configActions.setOperatingSystemDistro(distro))
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Template</h3>
      <p className="mb-6">Select the operating system template that will be deployed on this server.</p>

      <div className="flex flex-col gap-2 lg:gap-4">
        {showSkeleton ? Array(4).fill().map((value, index) => (
          <SkeletonCard key={index} className="h-[82px]" />
        )) : null}
        {!configState.isLoading && (configState.isError === '' || configState.isError === 'api-post') && availableDistros ? availableDistros.map((os) => (
          <SelectableCard
            ref={cardRef.current[os.id] ??= {current: null}}
            key={os.id}
            title={ <div className="text-xl md:text-2xl">{os.name}</div> }
            subheader={ <div className="text-xs text-lw-text-disabled">{os.bit}</div> }
            value={os.id}
            isSelected={configState.operatingSystemDistro === os.id}
            startElement={
              os.id && images?.[os.id]?.childImageSharp?.gatsbyImageData ? (
                <GatsbyImage
                  className="max-sm:!w-[30px] max-sm:!h-[30px]"
                  image={images[os.id].childImageSharp.gatsbyImageData}
                  alt={`${os.name} Logo`}
                />
              ) : null
            }
            endDivider
            endElement={getDistroVersionSelectField(os)}
            onClick={() => handleDistributionClick(cardRef.current[os.id], os.id, os.bit)}
          />
        )) : null}
      </div>
    </div>
  )
}
