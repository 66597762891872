import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z" fill="#757575"/>
  </svg>
)

export const Select = forwardRef(function Select({
  controlElemClass,
  selectWrapElemClass,
  label,
  size = 'lg',
  options,
  value,
  onChange,
  children
}, ref) {
  const arrowClasses = size === 'sm' ? [
    'py-[11px]',
    'pr-[11px]',
  ] : [
    'py-[15px]',
    'pr-[15px]',
  ]

  return (
    <div className={classnames(
      'flex',
      'flex-col',
      'gap-1',
      controlElemClass
    )}>
      {label && typeof label === 'string' ? <label>{label}</label> : null}
      {label && typeof label === 'object' ? label : null}
      <div className={classnames(
        'relative',
        'w-full',
        selectWrapElemClass
      )}>
        <span className={classnames(
          'absolute',
          'top-[2px]',
          'right-[2px]',
          'rounded-sm',
          'pl-6',
          'z-1',
          'pointer-events-none',
          'bg-gradient-to-r from-transparent via-white via-40% to-white',
          ...arrowClasses
        )}>
          <ArrowDown />
        </span>
        <select
          ref={ref}
          className={classnames(
            'px-4',
            'w-full',
            'border',
            'border-lw-ui-border',
            'hover:border-lw-ui-border-hover',
            'focus:border-lw-ui-border-hover',
            'rounded',
            'bg-white',
            '[appearance:none]',
            {
              'py-3': size !== 'sm',
              'py-2': size === 'sm',
            },
          )}
          onChange={onChange}
          value={value}
        >
          { !children && Array.isArray(options) ? options.map((option, index) => (
            <option key={`select-${option?.value ?? index}`} value={option?.value ?? option}>{option?.label ? option.label : option}</option>
          )) : null }
          {children ? children : null}
        </select>
      </div>
    </div>
  )
})

Select.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
  options: PropTypes.arrayOf([
    PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
}
