import React from 'react'

import CheckboxIcon from '../../../images/icon-checkbox-checked.inline.svg'
import { COMPARE_MANAGEMENT_LEVELS } from '../constants'

export function ComparisonTable() {
  const data = COMPARE_MANAGEMENT_LEVELS

  if (!data || !Array.isArray(data)) {
    return <></>
  }

  return (
    <table className="w-full text-sm">
      <thead>
        <tr className="border-b-2">
          <th className="px-2 pb-2"></th>
          <th className="px-2 pb-2 font-normal">
            <span className="hidden sm:block">Self-Managed</span>
            <span className="sm:hidden">Self</span>
          </th>
          <th className="px-2 pb-2 font-normal">
            <span className="hidden sm:block">Core-Managed</span>
            <span className="sm:hidden">Core</span>
          </th>
          <th className="px-2 pb-2 font-normal">
            <span className="hidden sm:block">Fully-Managed</span>
            <span className="sm:hidden">Fully</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={`row-${rowIndex}`} className="even:bg-gray-100">
            {row.map((content, tdIndex) => {
              return tdIndex === 0 ? (
                <td
                  key={`cell-${rowIndex}-${tdIndex}`}
                  className="p-2"
                >
                  {content}
                </td>
              ) : (
                <td key={`cell-${rowIndex}-${tdIndex}`} className="p-2 text-center">
                  {content ? <CheckboxIcon className="inline-block w-5 h-5 md:w-6 md:h-6" /> : '-'}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
