import React, { useEffect, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { find } from 'lodash'
import classnames from 'classnames'

import { useProductDetails } from '../../../hooks/useProductDetails'
import { useAppState } from '../../../state'
import { configurationHelper } from '../../../utils/configurationHelper'
import { removeDecimalIfWhole } from '../../../utils/removeDecimalIfWhole'
import configActions from '../../../state/configuration/actions'
import summaryActions from '../../../state/summary/actions'
import { HARDWARE_CATEGORY_ORDER, HARDWARE_CATEGORY_DESCRIPTIONS } from '../constants'

import { ButtonPill} from '../common/button/ButtonPill'
import { HardwareCard } from '../hardware-card'
import { Select } from '../common/select'
import { SkeletonCard } from '../common/skeleton-card'
import InfoIcon from '../../../images/icon-info.inline.svg'

import { IWORX_MIN_MEMORY_GB } from './SectionControlPanel'

export function SectionHardware() {
  const [{ configuration: state }, dispatch] = useAppState()
  const productData = useProductDetails()
  const { status, data } = productData[state.productCode]
  const { getHardwareOptionsByRegion, sortByKey } = configurationHelper(data)
  const showSkeleton = state.isLoading || state.isError === 'api-fetch' || state.isError === 'management'

  const images = useStaticQuery(graphql`{
    windows: file(relativePath: {eq: "server-os/windows-icon.png"}) {
      childImageSharp {
        gatsbyImageData(height: 16, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }`)

  const hardwareData = useMemo(() => {
    if (status === 'success') {
      const hardwareByCategory = []
      const configs = sortByKey(getHardwareOptionsByRegion(state.serverLocation), 'display_order')
      const newHardware = data?.hardware && data.hardware.map((level) => ({
        ...level,
        configs: prepareAvailableConfigs(level.configs, configs)
      }))

      if (state.hardwareTab === 'bare-metal') {
        return newHardware
      }

      HARDWARE_CATEGORY_ORDER.forEach((category) => {
        const match = find(newHardware, {'category': category})
        if (match) {
          hardwareByCategory.push(match)
        }
      })

      return hardwareByCategory
    }

    return []
  }, [status, state.productCode, state.managementLevel, state.serverLocation])

  useEffect(() => {
    const activeHardware = getCurrentHardwareConfigs()

    if (activeHardware && activeHardware?.[0]?.id) {
      // Set default hardware config
      dispatch(configActions.setHardwareOption(activeHardware[0].id))

      setWindowsAddOns(activeHardware[0])

      const activeCategory = hardwareData.find((o) => o.category === state.hardwareTab)

      dispatch(summaryActions.setHardwareCategory({
        title: '',
        value: activeCategory?.description ? activeCategory.description : '',
        cost: getMonthlyCost(activeHardware[0].price)
      }))
      dispatch(summaryActions.setHardwareDetails(activeHardware[0]))
    }
  }, [state.hardwareTab, state.operatingSystemId, state.productCode, state.serverLocation])

  // If InterWorx is selected but the current config has
  // insufficient RAM to support it, then we automatically
  // select the smallest config that is able to support
  // InterWorx and display a message to the user.
  useEffect(() => {
    const configs = getCurrentHardwareConfigs()
    const activeConfig = find(configs, { id: state.hardwareOption })

    if (!activeConfig) return
    if (state.controlPanel !== 'Interworx') return
    if (Number(activeConfig.memory) >= IWORX_MIN_MEMORY_GB) return

    const iworxMinConfig =
      configs
        .filter(val => Number(val.memory) >= IWORX_MIN_MEMORY_GB)
        .reduce((acc, val) =>
          Number(val.memory) < Number(acc.memory) ? val : acc
        ) ?? activeConfig

    updateHardwareSelection(iworxMinConfig)

    dispatch(configActions.toggleImplicitConfigUpdateNotice(true))

  // CAUTION: Hooks in this component are missing dependencies,
  // but fixing this is not straightforward. We are following the
  // prevailing pattern here for practical reasons, accepting
  // the possibility of subtle bugs until this app is sunset
  // in the near future.
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.controlPanel,
    state.hardwareOption,
  ])

  function prepareAvailableConfigs(potentialConfigs, availableConfigs) {
    const matchingConfigs = []

    potentialConfigs.forEach((config) => {
      const match = find(availableConfigs, {'id': config.id})
      if (match) {
        matchingConfigs.push(Object.assign(config, match))
      }
    })

    return sortByKey(matchingConfigs, 'display_order')
  }

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { 'unit': 'month' })

    return priceObject?.amount ? priceObject.amount : 0
  }

  function getDisplayCost(hardware) {
    const price = getMonthlyCost(hardware.price)

    const windowsLicenseObject = getWindowsLicenseObject(hardware)
    const windowsLicenseFee = windowsLicenseObject?.price
      ? getMonthlyCost(windowsLicenseObject.price)
      : 0

    if (state.operatingSystemType === 'windows' && windowsLicenseFee) {
      return price
        ? removeDecimalIfWhole(Number(price) + Number(windowsLicenseFee))
        : ''
    }

    return price ? removeDecimalIfWhole(price) : ''
  }

  function getCurrentHardwareConfigs() {
    const hardwareObject = find(hardwareData, (o) => o.category === state.hardwareTab)

    return hardwareObject?.configs ? hardwareObject.configs : []
  }

  function updateHardwareSelection(hardware) {
    setWindowsAddOns(hardware)
    dispatch(configActions.setHardwareOption(hardware.id))

    const activeCategory = hardwareData.find((o) => o.category === state.hardwareTab)

    dispatch(summaryActions.setHardwareCategory({
      title: '',
      value: activeCategory.description,
      cost: getMonthlyCost(hardware.price)
    }))
    dispatch(summaryActions.setHardwareDetails(hardware))
  }

  const handleHardwareSelectionClick = (hardware) => {
    updateHardwareSelection(hardware)
    dispatch(configActions.toggleImplicitConfigUpdateNotice(false))
  }

  function getWindowsLicenseObject(hardware) {
    const windowsLicense = find(hardware.included_options, {'key': 'WindowsLicense'})

    return windowsLicense?.values?.[0]
  }

  function getMsSqlObject(hardware) {
    const msSQL = find(hardware.included_options, {'key': 'MsSQL'})

    return msSQL ? msSQL : []
  }

  function setWindowsAddOns(hardware) {
    const msSqlObject = getMsSqlObject(hardware)
    const windowsLicenseObject = getWindowsLicenseObject(hardware)
    const windowsLicenseName = windowsLicenseObject?.description
      ? windowsLicenseObject.description
      : ''
    const windowsLicenseFee = windowsLicenseObject?.price
      ? removeDecimalIfWhole(getMonthlyCost(windowsLicenseObject.price))
      : 0

    dispatch(summaryActions.setWindowsLicense({
      value: windowsLicenseName,
      cost: windowsLicenseFee
    }))
    dispatch(configActions.setAvailableMsSql(msSqlObject?.values ? msSqlObject.values : []))
  }

  function getWindowsFeeNotice() {
    if ((state.isError === '' || state.isError === 'api-post') && state.operatingSystemType === 'windows') {
      return (
        <div className="flex items-center p-3 bg-lw-ui-light text-sm gap-3 rounded">
          <GatsbyImage className="grow-0 shrink-0" image={images.windows.childImageSharp.gatsbyImageData} alt="Windows Logo" />
          <span>Windows licensing fee is included in your hardware price.</span>
        </div>
      )
    }

    return null
  }

  function getControlPanelNotice() {
    if (state.serverType === 'vps' && state.controlPanel !== 'NoCP' && state.managementLevel !== 'Core-Managed') {
      const configs = getCurrentHardwareConfigs()
      const activeConfig = find(configs, {'id': state.hardwareOption})

      if (activeConfig?.memory && Number(activeConfig.memory) < 4) {
        return (
          <ul className="mt-3 text-sm">
            <li className={classnames(
              'relative',
              'pl-3',
              'before:absolute',
              'before:left-0',
              'before:pr-2',
              'before:inline-block',
              'before:content-["•"]',
              'before:align-middle',
            )}>{`For the best ${!state?.controlPanel || state.controlPanel === 'NoCP' ? 'Control Panel' : state.controlPanel} experience, we recommend at least 4 GB of memory.`}</li>
          </ul>
        )
      }
    }

    return null
  }

  function getHardwareCategoryInfo() {
    if (state.hardwareTab && state.hardwareTab !== 'bare-metal') {
      return HARDWARE_CATEGORY_DESCRIPTIONS && HARDWARE_CATEGORY_DESCRIPTIONS.get(state.hardwareTab)
        ? (
          <p>{HARDWARE_CATEGORY_DESCRIPTIONS.get(state.hardwareTab)}</p>
        ) : null
    }

    return null
  }

  const getShouldDisableOption = hardware =>
    state.controlPanel === 'Interworx' &&
    Number(hardware.memory) < IWORX_MIN_MEMORY_GB

  const hardwareCategoryInfo = getHardwareCategoryInfo()
  const controlPanelNotice = getControlPanelNotice()
  const windowsFeeNotice = getWindowsFeeNotice()

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Hardware</h3>
      <p className="mb-6">Physical components powering servers for data processing and storage.</p>

      {state.serverType === 'vps' ? (
        <div className="mb-6">
          <div className="gap-2 hidden sm:flex">
            {showSkeleton ? Array(3).fill().map((value, index) => (
              <SkeletonCard key={index} className="h-[42px] !rounded-full grow" />
            )) : null}
            {!state.isLoading && !state.isError && hardwareData ? hardwareData.map((filter) => (
              <ButtonPill
                key={filter.category}
                active={filter.category === state.hardwareTab}
                onClick={() => dispatch(configActions.setHardwareTab(filter.category))}
                className="lg:text-sm xl:text-base"
              >
                {filter.description}
              </ButtonPill>
            )) : null}
          </div>

          {showSkeleton ? (
            <SkeletonCard className="h-[50px] sm:hidden" />
          ) : (
              <Select
                controlElemClass="sm:hidden"
                onChange={(event) => dispatch(configActions.setHardwareTab(event.target.value))}
                value={state.hardwareTab}
              >
                {hardwareData ? hardwareData.map((filter, index) => (
                  <option key={index} value={filter.category}>{filter.description}</option>
                )) : null}
              </Select>
            )}
        </div>
      ) : null}
      
      {hardwareCategoryInfo || controlPanelNotice || windowsFeeNotice ? (
        <div className="mb-10 flex flex-col gap-3">
          {hardwareCategoryInfo || controlPanelNotice ? (
            <div className="p-3 bg-lw-ui-light text-sm flex gap-3 rounded">
              <div className="text-black basis-4 shrink-0 grow-0 pt-[2px]">
                <InfoIcon width="16" height="16" />
              </div>
              <div>
                {hardwareCategoryInfo}
                {controlPanelNotice}
              </div>
            </div>
          ) : null}

          {windowsFeeNotice}
        </div>
      ) : null}

      <div id="hardware" className={classnames(
        'grid',
        'gap-4',
        'md:gap-6',
        'lg:gap-4',
        'xl:gap-6',
        {
          'grid-cols-2': state.serverType === 'vps',
          'xl:grid-cols-3': state.serverType === 'vps',
          'grid-cols-1': state.serverType === 'metal',
          'sm:grid-cols-2': state.serverType === 'metal',
        }
      )}>
        {showSkeleton
          ? Array(state.serverType === 'vps' ? 9 : 4).fill().map((value, index) => (
            <SkeletonCard key={index} className="h-[148px]" />
          ))
          : null}
        {!state.isLoading && (state.isError === '' || state.isError === 'api-post')
          ? getCurrentHardwareConfigs().map((hardware) => (
            <HardwareCard
              key={hardware.id}
              serverType={state.serverType}
              isSelected={state.hardwareOption === hardware.id}
              onClick={() => handleHardwareSelectionClick(hardware)}
              data={hardware}
              cost={getDisplayCost(hardware)}
              billingCycle={'Monthly'}
              disabled={getShouldDisableOption(hardware)}
            />
          ))
          : null}
      </div>
    </div>
  )
}
