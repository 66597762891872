import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { useScrollBlock } from '../../../hooks/useScrollBlock'
import { useAppState } from '../../../state'
import configActions from '../../../state/configuration/actions'
import summaryActions from '../../../state/summary/actions'
import scrollToElement from '../../../utils/scrollToElement'
import {
  SUMMARY_HARDWARE_VPS,
  SUMMARY_HARDWARE_VPS_WIN,
  SUMMARY_HARDWARE_METAL,
  SUMMARY_HARDWARE_METAL_WIN,
  SUMMARY_ADDONS,
  SUMMARY_ADDONS_WIN,
  LOCATION_MAP,
} from '../constants'

import { SkeletonCard } from '../common/skeleton-card'

export function Summary({
  isSummaryOpen,
  setSummaryOpen,
  locationRef,
  serverTypeRef,
  templateControlsRef,
  managementRef,
  addOnsRef,
}) {
  const [{
    configuration: configState,
    summary: summaryState
  }, dispatch] = useAppState()
  const [blockScroll, allowScroll] = useScrollBlock()

  const handleSummaryOpen = useCallback((isOpen) => {
    setSummaryOpen(isOpen)
    isOpen ? blockScroll() : allowScroll()
  }, [setSummaryOpen, blockScroll, allowScroll])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1024) {
        handleSummaryOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleSummaryOpen])

  const {
    hardwareCategory,
    hardwareDetails,
    license,
    distro,
    management,
    controlPanel,
    controlPanelLicense,
  } = summaryState

  const images = useStaticQuery(graphql`{
    lan: file(relativePath: {eq: "locations/michigan.png"}) {
      childImageSharp {
        gatsbyImageData(width: 512, layout: FULL_WIDTH, quality: 100)
      }
    }
    phx: file(relativePath: {eq: "locations/arizona.png"}) {
      childImageSharp {
        gatsbyImageData(width: 512, layout: FULL_WIDTH, quality: 100)
      }
    }
    ams: file(relativePath: {eq: "locations/amsterdam.png"}) {
      childImageSharp {
        gatsbyImageData(width: 512, layout: FULL_WIDTH, quality: 100)
      }
    }
  }`)
  
  const summaryPanelClasses = classnames(
    'px-4',
    'sm:px-8',
    'lg:px-0',
    {
      'pt-10': isSummaryOpen,
      'pb-20': isSummaryOpen,
      'block': isSummaryOpen,
      'hidden': !isSummaryOpen
    },
    'lg:block',
  )
  const linkClasses = classnames(
    'text-xs',
    'underline',
    'text-lw-text-disabled',
    'hover:text-lw-text-primary',
    'transition-all',
  )

  const isWindows = configState.operatingSystemType === 'windows'
  const locationDisplayName = LOCATION_MAP?.get(configState.serverLocation)?.city ? LOCATION_MAP.get(configState.serverLocation).city : configState.serverLocation

  function getHardwareList() {
    let map = SUMMARY_HARDWARE_VPS
    if (configState.serverType === 'metal') {
      map = isWindows ? SUMMARY_HARDWARE_METAL_WIN : SUMMARY_HARDWARE_METAL
    } else {
      map = isWindows ? SUMMARY_HARDWARE_VPS_WIN : SUMMARY_HARDWARE_VPS
    }

    let list = []
    map.forEach((item, index) => {
      list.push(
        <div key={index}>
          <span className="text-lw-text-disabled">{`${item.label}: `}</span>
          {`${hardwareDetails[index]} ${item.suffix}`}
        </div>
      )
    })

    return list
  }

  function handleJumpToSection(ref) {
    if (ref) {
      scrollToElement(ref, 180)
    }
    handleSummaryOpen(false)
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'group/body',
        }}
      />
      <div className="lg:hidden">
        <button className="flex justify-between items-center w-full px-4 sm:px-10 py-4" onClick={ () => handleSummaryOpen(!isSummaryOpen) }>
          <div className="grow text-base text-left uppercase">
            {`Your ${ configState.serverType === 'vps' ? 'Cloud VPS' : 'Cloud Metal'}`}
          </div>
          <span className="text-xs text-lw-text-disabled underline">
            {isSummaryOpen ? 'Configure' : 'Summary'}
          </span>
        </button>
      </div>

      <div className={summaryPanelClasses}>

        <h2 className="hidden lg:block text-xl font-normal mt-0 mb-4">
          {`${ configState.serverType === 'vps' ? 'Cloud VPS' : 'Cloud Metal'} Summary`}
        </h2>

        <div className="lg:max-h-[63vh] lg:pr-8 lg:-mr-8 lg:overflow-y-auto">

          {configState.serverLocation ? (
            <div className="mb-4 rounded-lg bg-lw-ui-dark">
              <button className="w-full block" onClick={() => handleJumpToSection(locationRef)}>
                {images?.[configState.serverLocation] ? (
                  <GatsbyImage
                    className="rounded-lg"
                    image={images[configState.serverLocation].childImageSharp.gatsbyImageData}
                    alt={`${locationDisplayName} Location Map`}
                  />
                ) : (
                  <div className="flex items-center justify-center aspect-[4/1] text-center text-white text-xs">
                    <span>{locationDisplayName}</span>
                  </div>
                )}
              </button>
            </div>
          ) : null}

          <table className="text-sm w-full" aria-label="Configuration Hardware Choices">
            <caption>
              <div className="flex justify-between w-full text-xs mb-2">
                <span className="uppercase text-lw-text-disabled tracking-wide">Hardware</span>
                <button
                  aria-label="Edit hardware configuration choices"
                  className={linkClasses}
                  onClick={() => handleJumpToSection(templateControlsRef)}
                >Edit</button>
              </div>
            </caption>
            <thead className="sr-only">
              <tr>
                <th>Product Choice</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {configState.isLoading || configState.isError === 'api-fetch' ? (
                Array(5).fill().map((value, index) => (
                  <tr key={index}>
                    <td>
                      <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="align-top">
                  <td>
                    <div>{hardwareCategory?.value ? hardwareCategory.value : ''}</div>
                    <div className="options">
                      {getHardwareList()}
                    </div>
                  </td>
                  <td className="text-right">{hardwareCategory?.cost ? `$${hardwareCategory.cost}` : ''}</td>
                </tr>
              )}
              {!configState.isLoading && configState.isError !== 'api-fetch' && isWindows ? (
                <tr className="align-top">
                  <td>
                    <span className="text-lw-text-disabled">{license?.title ? `${license.title}: ` : ''}</span>
                    {license?.value ? license.value : ''}
                  </td>
                  <td className="text-right">{license?.cost ? `$${license.cost}` : ''}</td>
                </tr>
              ) : null}
            </tbody>
          </table>

          <hr className="border-t border-lw-ui-border my-4 " />

          <table className="text-sm w-full" aria-label="Operating System Choices">
            <caption>
              <div className="flex justify-between w-full text-xs mb-2">
                <span className="uppercase text-lw-text-disabled tracking-wide">OS</span>
                <button
                  aria-label="Edit Operating System configuration choices"
                  className={linkClasses}
                  onClick={() => handleJumpToSection(managementRef)}
                >Edit</button>
              </div>
            </caption>
            <thead className="sr-only">
              <tr>
                <th>Operating System</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {configState.isLoading || configState.isError === 'api-fetch' ? (
                Array(5).fill().map((value, index) => (
                  <tr key={index}>
                    <td>
                      <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td>Template</td>
                    <td></td>
                  </tr>
                  <tr className="align-top">
                    <td>
                      <span className="text-lw-text-disabled">{`${distro.title}: `}</span>
                      {distro.value}
                    </td>
                    <td className="text-right"></td>
                  </tr>
                  <tr className="align-top">
                    <td>
                      <span className="text-lw-text-disabled">{`${management.title}: `}</span>
                      {management.value}
                    </td>
                    <td className="text-right">{`$${management.cost}`}</td>
                  </tr>
                  <tr className="align-top">
                    <td>
                      <span className="text-lw-text-disabled">{`${controlPanel.title}: `}</span>
                      {controlPanel.value}
                    </td>
                    <td className="text-right">{`$${controlPanel.cost}`}</td>
                  </tr>
                  {controlPanelLicense.value ? (
                    <tr className="align-top">
                      <td>
                        <span className="text-lw-text-disabled">{`${controlPanelLicense.title}: `}</span>
                        {controlPanelLicense.value}
                      </td>
                      <td className="text-right">{`$${controlPanelLicense.cost}`}</td>
                    </tr>
                  ) : null}
                </>
              )}
            </tbody>
          </table>

          <hr className="border-t border-lw-ui-border my-4 " />

          <table className="text-sm w-full" aria-label="Configuration Add-On Choices">
            <caption>
              <div className="flex justify-between w-full text-xs mb-2">
                <span className="uppercase text-lw-text-disabled tracking-wide">Add-Ons</span>
                <button
                  aria-label="Edit Add-On choices"
                  className={linkClasses}
                  onClick={() => handleJumpToSection(addOnsRef)}
                >Edit</button>
              </div>
            </caption>
            <thead className="sr-only">
              <tr>
                <th>Backup</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {configState.isLoading || configState.isError === 'api-fetch' ? (
                Array(5).fill().map((value, index) => (
                  <tr key={index}>
                    <td>
                      <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
                    </td>
                  </tr>
                ))
              ) : null}
              {!configState.isLoading && configState.isError !== 'api-fetch' && SUMMARY_ADDONS.map((key, index) => {
                const option = summaryState?.[key]
                const cost = option.cost ? `$${option.cost}` : ''

                return (option ? (
                  <tr key={index} className="align-top">
                    <td>
                      <span className="text-lw-text-disabled">{`${option.title}: `}</span>
                      {option.value}
                    </td>
                    <td className="text-right">{cost}</td>
                  </tr>
                ) : null)
              })}
              {!configState.isLoading && configState.isError !== 'api-fetch' && isWindows && SUMMARY_ADDONS_WIN.map((key, index) => {
                const option = summaryState?.[key]
                const cost = option.cost ? `$${option.cost}` : ''

                return (option ? (
                  <tr key={index} className="align-top">
                    <td>
                      <span className="text-lw-text-disabled">{`${option.title}: `}</span>
                      {option.value}
                    </td>
                    <td className="text-right">{cost}</td>
                  </tr>
                ) : null)
              })}
            </tbody>
          </table>

          <div className="mt-4 lg:pb-32">
            <button className={linkClasses} onClick={() => {
              handleJumpToSection(serverTypeRef)
              handleSummaryOpen(false)
              dispatch(summaryActions.resetSummary())
              dispatch(configActions.resetConfiguration())
            }}>Reset My Configuration</button>
          </div>

        </div>
      </div>
    </>
  )
}

Summary.propTypes = {
  isSummaryOpen: PropTypes.bool,
  setSummaryOpen: PropTypes.func,
  locationRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  serverTypeRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  templateControlsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  managementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  addOnsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
}
