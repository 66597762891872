import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export function Switch({
  id,
  size = 'lg',
  label,
  colorUnchecked = 'bg-lw-ui-warning',
  colorChecked = 'peer-checked:bg-lw-ui-green',
  checked = false,
  onChange,
}) {
  const elementId = id ? id : `lw-switch-${Math.floor(Math.random() * 100)}`

  const sizeBasedClasses = size === 'sm' ? [
    'w-[24px]',
    'h-[14px]',
    'after:h-[8px]',
    'after:w-[8px]',
    'after:top-[3px]',
    'after:left-[3px]',
    'peer-checked:after:translate-x-[10px]',
  ] : [
    'w-[36px]',
    'h-[20px]',
    'after:h-[12px]',
    'after:w-[12px]',
    'after:top-[4px]',
    'after:left-[4px]',
    'peer-checked:after:translate-x-[16px]',
  ]

  return (
    <div className="flex align-center">
      <label id={elementId} className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          id={elementId}
          checked={checked}
          onChange={onChange}
          className="sr-only peer"
        />
        <div className={classnames(
          'relative',
          'peer-focus:outline-none',
          'peer-focus:ring-2',
          'peer-focus:ring-offset-2',
          'peer-focus:ring-lw-ui-border-disabled',
          'rounded-full',
          'peer',
          'after:content-[""]',
          'after:absolute',
          'after:bg-white',
          'after:rounded-full',
          'after:transition-all',
          `${colorUnchecked}`,
          `${colorChecked}`,
          ...sizeBasedClasses
        )} />
        {label && typeof label === 'string' ? (
          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
        ) : null}
        {label && typeof label !== 'string' ? label : null}
      </label>
    </div>
  )
}

Switch.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(['sm','lg']),
  colorUnchecked: PropTypes.string,
  colorChecked: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}
