import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export function SkeletonCard({ className, children }) {
  const skeletonClasses = classnames(
    'rounded-lg',
    'border',
    'border-lw-ui-border',
    'animate-pulse',
    'bg-lw-disabled',
    className
  )

  return (
    <div className={skeletonClasses}>
      {children ? children : null}
    </div>
  )
}

SkeletonCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

