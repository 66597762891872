import React from 'react'
import classnames from 'classnames'

import { useAppState } from '../../../state'
import configActions from '../../../state/configuration/actions'
import summaryActions from '../../../state/summary/actions'

import { SkeletonCard } from '../common/skeleton-card'
import { Switch } from '../common/switch'
import { Chip } from '../common/chip'

export function SectionBackUps() {
  const [{ configuration: state }, dispatch] = useAppState()
  const showSkeleton = state.isLoading || state.isError === 'api-fetch' || state.isError === 'management'

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Cloud Backups <span className="font-normal text-sm text-lw-text-disabled">(Optional)</span></h3>
      <p className="mb-6">Liquid Web provides automatic backups essential for critical systems. Use a saved image to quickly restore in case of issues.</p>

      {showSkeleton ? (
        <SkeletonCard className="h-[100px]" />
      ) : (
        <div
          className={classnames(
            'p-4',
            'sm:p-6',
            'flex-col',
            'w-full',
            'border',
            'border-lw-ui-border',
            'rounded-lg',
            {
              'border-lw-ui-green': state.backupsEnabled,
              'bg-white': state.backupsEnabled
            }
          )}
        >
          <div className="flex justify-between gap-4 sm:gap-0 flex-col-reverse sm:flex-row items-start sm:items-center">
            <Switch
              label={<span className="ml-6 leading-none text-2xl">Enable Backups</span>}
              checked={state.backupsEnabled}
              onChange={() => {
                dispatch(summaryActions.setBackups({
                  value: !state.backupsEnabled ? 'Yes ($0.05 per GB/Month used)' : 'No',
                  cost: 0
                }))
                dispatch(configActions.setBackups())
              }}
            />
            <Chip className="ml-[60px] sm:ml-0">+$0.05 per GB/month</Chip>
          </div>
          <p className="text-sm text-lw-text-disabled leading-none ml-[60px] mt-2">
            {`By default, we keep three backups, taken daily, billed at cycle's end.`}
          </p>
        </div>
      )}
    </div>
  )
}
